import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"

import Layout from '../components/Layout'
import Input from '../components/Input';
import Popup from '../components/Popup'
import Button from '../components/Button'

import LoginScreen from './LoginScreen';
import IPBanned from './IPBanned';
import PurchaseInfo from './PurchaseInfo';

import { iq180list, bloctoUserList } from '../list';
import banned from '../banned';
import { amountValidators } from '../amountValidators';
import logoFlow from '../assets/flow-logo.svg'
import checkImg from '../assets/ic-success.svg'
import { getUserInfo } from '../services/Blocto'
import { getItem, setItem, KEY_USER_ID } from '../services/LocalStorage'
import useGeoLocation from '../hooks/useGeoLocation'
import useGetIsSaleActive from '../hooks/useGetIsSaleActive'
import useGetBalance from '../hooks/useGetBalance'
import useGetPurchaseInfo from '../hooks/useGetPurchaseInfo'

const purchaseTransaction = `\
import NonFungibleToken from 0x1d7e57aa55817448
import TeleportedTetherToken from 0xcfdd90d4a00f7b5b
import BloctoPass from 0x0f9df91c9121c460
import BloctoTokenSale from 0x0f9df91c9121c460

transaction(amount: UFix64) {

    // The tUSDT Vault resource that holds the tokens that are being transferred
    let sentVault:  @TeleportedTetherToken.Vault

    // The address of the BLT buyer
    let buyerAddress: Address

    prepare(account: AuthAccount) {

        // Get a reference to the signer's stored vault
        let vaultRef = account.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

        // Withdraw tokens from the signer's stored vault
        self.sentVault <- vaultRef.withdraw(amount: amount) as! @TeleportedTetherToken.Vault

        // Record the buyer address
        self.buyerAddress = account.address

        // If user does not have BloctoPass collection yet, create one to receive
        if account.borrow<&BloctoPass.Collection>(from: /storage/bloctoPassCollection) == nil {

            let collection <- BloctoPass.createEmptyCollection() as! @BloctoPass.Collection

            account.save(<-collection, to: /storage/bloctoPassCollection)

            account.link<&{NonFungibleToken.CollectionPublic, BloctoPass.CollectionPublic}>(
                /public/bloctoPassCollection,
                target: /storage/bloctoPassCollection)
        }
    }

    execute {

        // Enroll in BLT community sale
        BloctoTokenSale.purchase(from: <-self.sentVault, address: self.buyerAddress)
    }
}
`

const Content = styled.div`
  font-size: 14px;
  line-height: 1.57;

  p {
    margin-block-start: 0;
  }

  a {
    color: #333333;

    &:hover {
      text-decoration: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

const AccountControl = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const FlowLogo = styled.img`
  width: 40px;
  height: 40px;
`;

const WalletName = styled.div`
  width: initial;
  font-size: 12px;
  color: #333333;
  text-transform: uppercase;
`;

const WalletAddress = styled.a`
  color: #131313;
  font-weight: 500;
  font-size: 1rem;
`;

const ActionButton = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: rgb(42, 74, 197);
  color: white;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 20px;
  flex: 0 0 auto;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
  pointer-events: ${props => props.isDisabled ? 'none' : 'inherit'};
  opacity: ${props => props.isDisabled || props.isProcessing ? 0.5 : 1};

  &:hover {
    background-color: rgb(38, 66, 176);
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 10px;
  padding: 14px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

const ContentQualified = styled(Content)`
  border-radius: 15px;
  background-color: #f6f6f6;
  padding: 18px;
  margin-bottom: 20px;

  a {
    color: #333333;
  }

  li {
    margin-bottom: 4px;
  }
`;

const TierRow = styled.div`
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #D7DEE2;
  margin-bottom: 20px;
`;

const CheckImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const AdjustInputWrapper = styled.div`
  backdrop-filter: blur(20px);
  background-color: rgba(20, 20, 20, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  pointer-events: ${props => (props.isVisible ? 'inherit' : 'none')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: .3s opacity;
`;

const StyledCard = styled(Popup)`
  transform: translate(-50%, -50%) translateY(${props => (props.isVisible ? 0 : 30)}px);
  transition: .3s transform;
`;

const ConfirmAmount = styled.div`
  margin: 28px 0;
  color: #1336BF;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  display: flex;
  justify-content: center;

  &:after {
    content: "tUSDT";
    position: absolute;
    right: 4px;
    bottom: 2px;
    font-size: 16px;
  }
`;

const TokenSale = () => {
  const [user, setUser] = useState(null)
  const [accessToken, setAccessToken] = useState('')
  const [userId, setUserId] = useState(getItem(KEY_USER_ID, ''))
  const [error, setError] = useState(false)
  const [amount, setAmount] = useState('')
  const [isConfirmVisible, setConfirmVisible] = useState(false)
  const [isProcessing, setProcessing] = useState(false);

  const geoLocation = useGeoLocation()
  const isSaleActive = useGetIsSaleActive()
  const balance = useGetBalance(user && user.addr)
  const purchaseInfo = useGetPurchaseInfo(user && user.addr, isProcessing)

  const isBanned = useMemo(
    () => banned.includes(geoLocation),
    [geoLocation]
  )

  const isLoggedIn = useMemo(
    () => Boolean(user && user.addr),
    [user]
  )

  const tierMessage = useMemo(
    () => {
      if (!userId) {
        return ''
      }

      if (iq180list.includes(userId)) {
        return <span><b>Bloctopian Quiz Winner</b>. You're guarenteed a spot in the community sale!</span>
      }

      if (bloctoUserList.includes(userId)) {
        return <span><b>Blocto Early User</b>. Your chance of winning has largely increased!</span>
      }

      return ''
    },
    [userId]
  )

  const messageProcessor = (event) => {
    try {
      if (event.data.type === "FCL::BLOCTO::INTERNAL") {
        setAccessToken(event.data.accessToken);
      }
    } catch (error) {
      console.error("Frame Callback Error", error)
    }
  }

  const handleChangeWallet = () => {
    fcl.unauthenticate()
  }

  const handleError = ({ hasError }) => {
    setError(hasError);
  }

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  }

  const handleSubmit = () => {
    if (error || !amount) {
      return;
    }

    setProcessing(true)
    setConfirmVisible(true)
  };

  const handleConfirm = async (event) => {
    event.preventDefault()
    setConfirmVisible(false)

    const blockResponse = await fcl.send([
      fcl.getLatestBlock(),
    ])

    const block = await fcl.decode(blockResponse)

    try {
      const { transactionId } = await fcl.send([
        fcl.transaction(purchaseTransaction),
        fcl.args([
          fcl.arg(parseFloat(amount).toFixed(8), t.UFix64),
        ]),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.authorizations([
          fcl.currentUser().authorization,
        ]),
        fcl.payer(fcl.currentUser().authorization),
        fcl.ref(block.id),
        fcl.limit(1000),
      ])

      const unsub = fcl
        .tx({ transactionId })
        .subscribe(transaction => {
          if (fcl.tx.isSealed(transaction)) {
            setProcessing(false)
            unsub()
          }
        })
    } catch (error) {
      console.error(error);
      alert(error);
      setProcessing(false)
    }
  }

  const handleCloseConfirm = () => {
    setProcessing(false)
    setConfirmVisible(false)
  }

  useEffect(() => {
    window.addEventListener('message', messageProcessor)

    fcl
      .currentUser()
      .subscribe(user => {
        setUser({ ...user });
      })

    return () => {
      window.removeEventListener('message', messageProcessor);
    }
  }, [])

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    getUserInfo({ accessToken })
      .subscribe(({ id }) => {
        setUserId(id);
        setItem(KEY_USER_ID, id);
      })
  }, [accessToken]);

  if (isBanned) {
    return (
      <Layout><IPBanned /></Layout>
    )
  }

  if (!isLoggedIn) {
    return (
      <Layout><LoginScreen /></Layout>
    )
  }

  if (purchaseInfo) {
    return (
      <Layout>
        <InfoCard>
          <FlowLogo src={logoFlow} alt={'flow logo'} />
          <AccountControl>
            <WalletName>
              Account Address
            </WalletName>

            <WalletAddress>
              {user.addr}
            </WalletAddress>
          </AccountControl>
          <ActionButton onClick={fcl.unauthenticate}>Change</ActionButton>
        </InfoCard>

        <PurchaseInfo purchaseInfo={purchaseInfo} />
      </Layout>
    )
  }

  return (
    <Layout>
      <>
        <InfoCard>
          <FlowLogo src={logoFlow} alt={'flow logo'} />
          <AccountControl>
            <WalletName>
              Account Address
            </WalletName>

            <WalletAddress>
              {user.addr}
            </WalletAddress>
          </AccountControl>
          <ActionButton onClick={handleChangeWallet}>Change</ActionButton>
        </InfoCard>

        <>
          <ContentQualified>
            <TierRow>
              {tierMessage && <CheckImg src={checkImg} />}
              {tierMessage || 'Place your order and get a chance for $BLT community sale allocation!'}
            </TierRow>

            <ul>
              <li>Token name: <b> <a href="https://flowscan.org/contract/A.0f9df91c9121c460.BloctoToken" target="_blank" rel="noopener noreferrer">Blocto Token ($BLT)</a></b></li>
              <li>Community sale price: <b>0.1 tUSDT / BLT</b></li>
              <li>Vesting period: <b>7-24 months</b></li>
              <li>Personal cap: <b>1,000</b> tUSDT</li>
              <li>tUSDT Balance: <b>{parseFloat(balance)}</b> <a href="https://guide.blocto.app/article/bloctoswap-tutorial" target="_blank" rel="noopener noreferrer">Get tUSDT</a></li>
            </ul>

            <InputRow>
              <Input
                validators={amountValidators(balance)}
                onError={handleError}
                onChange={handleAmountChange}
                placeholder="Enter amount"
                type="number"
                unit="tUSDT"
              />
            </InputRow>

            <SubmitButton
              onClick={handleSubmit}
              isDisabled={error || !amount || parseFloat(amount) <= 0 || isProcessing || !isSaleActive}
              isProcessing={isProcessing}
            >
              {isSaleActive ? 'Submit' : 'Open on July 13th 4PM (UTC+8)'}
            </SubmitButton>
          </ContentQualified>
          <Content>
            Please read the following rules and guides before you particiapte in the $BLT community sale.
            <ul>
              <li>
                <a href="https://medium.com/portto/blocto-is-excited-to-announce-the-community-sale-of-our-utility-token-blt-4233e0f52989" target="_blank" rel="noopener noreferrer">Blocto Token community sale rules.</a>
              </li>
              <li>
                <a href="https://medium.com/portto/blocto-is-excited-to-announce-the-community-sale-of-our-utility-token-blt-4233e0f52989" target="_blank" rel="noopener noreferrer">Blocto Token community sale lockup terms.</a>
              </li>
              <li>
                <a href="https://guide.blocto.app/article/bloctoswap-tutorial" target="_blank" rel="noopener noreferrer">Learn how you can get tUSDT.</a>
              </li>
              <li>You can place your order between <b>July 13-15 4PM (UTC+8)</b>.</li>
              <li>We will announce winners and distribute/refund before <b>July 22th</b>.</li>
            </ul>
          </Content>

          <AdjustInputWrapper isVisible={isConfirmVisible}>
            <StyledCard
              isVisible={isConfirmVisible}
              title="Confirm Submission"
              onClose={handleCloseConfirm}
            >
              <ContentQualified>
                Please read the following rules and guides before you particiapte in the $BLT community sale.
                <ul>
                  <li>
                    <a href="https://medium.com/portto/blocto-is-excited-to-announce-the-community-sale-of-our-utility-token-blt-4233e0f52989" target="_blank" rel="noopener noreferrer">Blocto Token community sale rules.</a>
                  </li>
                  <li>
                    <a href="https://medium.com/portto/blocto-is-excited-to-announce-the-community-sale-of-our-utility-token-blt-4233e0f52989" target="_blank" rel="noopener noreferrer">Blocto Token community sale lockup terms.</a>
                  </li>
                  <li>
                    <a href="https://guide.blocto.app/article/bloctoswap-tutorial" target="_blank" rel="noopener noreferrer">Learn how you can get tUSDT.</a>
                  </li>
                  <li>You can place your order between <b>July 13-15 4PM (UTC+8)</b>.</li>
                  <li>We will announce winners and distribute/refund before <b>July 22th</b>.</li>
                </ul>
              </ContentQualified>

              <Content>
                You can only place your order once. Please confirm your order.
              </Content>

              <ConfirmAmount>{amount}</ConfirmAmount>

              <SubmitButton
                onClick={handleConfirm}
              >
                Confirm
              </SubmitButton>
            </StyledCard>
          </AdjustInputWrapper>
        </>
      </>
    </Layout>
  )
}

export default TokenSale
