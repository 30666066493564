import React from 'react';
import styled from 'styled-components';
import close from '../assets/ic-close.svg';
import media from '../utils/media';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  max-width: 90vw;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;

  ${media.xxs`
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
  `}
`;

const Header = styled.div`
  flex: 0 0 40px;
  border-bottom: 1px solid #EFEFEF;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  text-align: center;
  text-transform: capitalize;
`;

const Title = styled.div`
  flex: 1;
`;

const Close = styled.div`
  cursor: pointer;
  border-radius: 4px;
  cursor: pointer;
  transition: .2s transform;
  transform: scale(1);
  
  &:hover {
    transform: scale(1.15);
  }
`;

const Body = styled.div`
  flex: 1;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${media.xxs`
    padding: 10px 12px;
  `}
`;

type CardType = {
  title: String,
  onClose: Function,
  className: String,
};

const Popup = ({ children, title, onClose, className }: CardType) => (
  <Wrapper className={className}>
    <Header>
      <Title>{title}</Title>

      {onClose && <Close onClick={onClose}>
        <img src={close} alt="close" />
      </Close>}
    </Header>

    <Body>
      {children}
    </Body>
  </Wrapper>
);

export default Popup;
