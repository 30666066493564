// import {
//   env,
//   ENV_MAINNET,
//   ENV_TESTNET,
//   ENV_LOCAL,
// } from '@/services/Env';

// const getUrl = prefix => `https://${prefix}.blocto.app/`;

// export const BASE_RESTFUL_URL = {
//   [ENV_MAINNET]: getUrl('api'),
//   [ENV_TESTNET]: getUrl('api-staging'),
//   [ENV_LOCAL]: getUrl('api-staging'),
// }[env] || getUrl('api-staging');

export const BASE_RESTFUL_URL = 'https://flow-wallet.blocto.app/';
