import React from "react"
import styled from "styled-components"
import * as fcl from "@onflow/fcl"

import Button from '../components/Button'

const Content = styled.div`
  font-size: 15px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.57;

  p {
    margin-block-start: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const LoginScreen = () => {

  return (
    <>
      <Content>
        <p>
          As a way to show our gratitude to early and active Blocto users, we're offering a limited number of users the same sale terms as our private sale backers.
        </p>

        <p>
          Please follow the instructions to participate.
        </p>

        <p>
          <i>Note that residents from countries or regions such as US, Mainland China, Hong Kong, Macau, Taiwan and UN-sanctioned countries are excluded from participating in the community sale.</i>
        </p>
      </Content>
      <Button onClick={fcl.authenticate}>Connect Wallet</Button>
    </>
  );
};

export default LoginScreen;
