const maxPurchaseAmount = input =>
  parseFloat(input) <= 1000;

const userBalance = balance => input =>
  parseFloat(input) <= parseFloat(balance);

export const amountValidators = (balance) => [
  {
    test: maxPurchaseAmount,
    message: 'Exceeds max purchase amount.',
  },
  {
    test: userBalance(balance),
    message: 'Insufficient tUSDT balance.',
  },
];
