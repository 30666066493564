import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { timingFunctions } from 'polished';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';

const getBorderColor = (props) => {
  if (props.hasError) {
    return 'rgba(217, 9, 9, 0.5)!important';
  }

  if (props.hasWarning) {
    return '#F69355!important';
  }

  if (props.hasFocus) {
    return '#5972d2!important';
  }

  return '#a7d6f9';
};

const getBackgroundColor = (props) => {
  if (props.hasError) {
    return 'rgba(251, 230, 230, 0.5)!important';
  }

  return 'rgba(232, 237, 249, 0.5)';
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${getBorderColor};
  background: ${getBackgroundColor};
  border-radius: 8px;
  transition: .2s border-color;
  pointer-events: ${props => props.isDisabled ? 'none' : 'inherit'};
`;

const InputComponent = styled.input`
  font-size: 14px;
  text-overflow: ellipsis;
  color: #141414;
  background: ${props => (props.readOnly ? '#F8F8F8' : 'transparent')};
  padding: 12px 14px;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: -webkit-fill-available;

  &:focus {
    outline: none;
  }
`;

const Unit = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F8F8F8;
  border-left: 1px solid #D7DEE2;
  color: #131313;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0px;
  z-index: 100;
  font-size: 12px;
  background-color: #FFFFFF;
  pointer-events: none;
  border-radius: 4px;
  border: 1px solid #D7DEE2;
  opacity: ${props => (props.isActive && props.hasContent ? 1 : 0)};
  transition: .1s opacity ${timingFunctions('easeInOutQuad')};
  padding: 4px 6px;
`;

const Error = styled.div`
  color: #d90909;
`;

const Warning = styled.div`
  color: #F69355;
`;

class Input extends PureComponent {
  props: {
    className: String,
    unit: String,
    isOptional: Boolean,
    disabled: Boolean,
    validators: Array<Function>,
    onChange: Function,
    onChangeStart: Function,
    onError: Function,
    value: String,
    unit: String,
  };

  static defaultProps = {
    validators: [],
    onChange: noop,
    onError: noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
      errors: [],
      warnings: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.handleValidation(this.props);
    }
  }

  handleFocus = () => this.setState({ hasFocus: true });
  handleBlur = () => this.setState({ hasFocus: false });

  handleChange = (event) => {
    event.persist();

    this.handleUpdate(event);

    const { onChangeStart } = this.props;
    if (onChangeStart) onChangeStart();
  }

  handleUpdate = debounce((event) => {
    const { onChange } = this.props;
    const { target: { name, value } } = event;

    onChange(event);
    this.handleValidation({ name, value });
  }, 300);

  handleValidation = ({ name, value }) => {
    const { onError, validators } = this.props;

    const errors = [];
    const warnings = [];

    if (value) {
      validators.forEach(({ test, message, isWarning }) => {
        if (!test(value)) {
          if (isWarning) {
            warnings.push(message);
          } else {
            errors.push(message);
          }
        }
      });
    }
    this.setState({ errors, warnings }, () => {
      onError({
        name,
        hasError: Boolean(this.state.errors.length),
      });
    });
  }

  render() {
    const { className, onChange, isOptional, disabled, unit, ...props } = this.props;
    const { hasFocus, errors, warnings } = this.state;
    const hasError = errors.length;
    const hasWarning = warnings.length;

    return (
      <Wrapper
        className={className}
        hasFocus={hasFocus}
        hasError={hasError}
        hasWarning={hasWarning}
        isOptional={isOptional}
        isDisabled={disabled}
      >
        <InputComponent
          {...props}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />

        {unit && <Unit>{unit}</Unit>}

        <TooltipBox
          isActive={hasFocus}
          hasContent={hasError || hasWarning}
        >
          {errors.map(error => (
            <Error key={error.id}>
              {error}
            </Error>
          ))}

          {warnings.map(warning => (
            <Warning key={warning.id}>
              {warning}
            </Warning>
          ))}
        </TooltipBox>
      </Wrapper>
    );
  }
}

export default Input;
