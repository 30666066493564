import { useState, useEffect } from 'react'
import * as fcl from '@onflow/fcl'
import * as t from "@onflow/types"
import useTimer from './useTimer'

const script = `
import FungibleToken from 0xf233dcee88fe0abe
import TeleportedTetherToken from 0xcfdd90d4a00f7b5b

pub fun main(account: Address): UFix64 {
  let balanceRef = getAccount(account).getCapability(TeleportedTetherToken.TokenPublicBalancePath)!
    .borrow<&TeleportedTetherToken.Vault{FungibleToken.Balance}>()

  return balanceRef?.balance ?? 0.0
}
`

export default function useGetBalance(address) {
  const [balance, setBalance] = useState('0.0')

  // auto refresh every 10 seconds
  const nonce = useTimer(10000)

  useEffect(() => {
    if (!address) {
      setBalance('0.0')
      return;
    }

    fcl.send([
      fcl.script(script),
      fcl.args([fcl.arg(address, t.Address)]),
    ])
      .then(fcl.decode)
      .then(setBalance);
  }, [nonce, address])

  return balance
}
