const banned = [
  'US',
  'CN',
  'TW',
  'HK',
  'MO',
  'ZA',
  'IQ',
  'KP',
  'HT',
  'IR',
  'LR',
  'LY',
  'SD',
  'SS',
  'YU',
  'RW',
];

export default banned;
