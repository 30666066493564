import React from "react"
import styled from "styled-components"

const Content = styled.div`
  font-size: 15px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.57;

  p {
    margin-block-start: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentNotQualified = styled(Content)`
  border-radius: 15px;
  background-color: rgba(217, 9, 9, 0.1);
  color: #d90909;
  padding: 18px;
  text-align: center;
`;

const IPBanned = () => {
  return (
    <>
      <ContentNotQualified>
        <p>
          <b>Sorry, you CANNOT participate in the Blocto Token community sale.</b>
        </p>
        <p>
          <i>Residents from countries or regions such as US, Mainland China, Hong Kong, Macau, Taiwan and UN-sanctioned countries are excluded from participating in the community sale.</i>
        </p>
      </ContentNotQualified>
    </>
  );
};

export default IPBanned;
