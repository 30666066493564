import { useState, useEffect } from 'react'
import * as fcl from '@onflow/fcl'
import useTimer from './useTimer'

const script = `
import BloctoTokenSale from 0x0f9df91c9121c460

pub fun main(): Bool {
    return BloctoTokenSale.getIsSaleActive()
}
`

export default function useGetIsSaleActive() {
    const [isSaleActive, setIsSaleActive] = useState(false)

    // auto refresh every 10 seconds
    const nonce = useTimer(10000)

    useEffect(() => {
        fcl.send([
            fcl.script(script),
        ])
            .then(fcl.decode)
            .then(setIsSaleActive);
    }, [nonce])

    return isSaleActive
}
