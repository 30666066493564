import React from "react"
import styled from "styled-components"

const Content = styled.div`
  font-size: 15px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.57;

  h3, p {
    margin-block-start: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: #333333;

    &:hover {
      text-decoration: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentQualified = styled(Content)`
  border-radius: 15px;
  background-color: #f6f6f6;
  padding: 18px;
  margin-bottom: 20px;

  li {
    margin-bottom: 4px;
  }
`;

const ContentNotQualified = styled(Content)`
  border-radius: 15px;
  background-color: rgba(217, 9, 9, 0.1);
  color: #d90909;
  padding: 18px;
  text-align: center;
`;

const PurchaseInfo = ({ purchaseInfo }) => {
  if (purchaseInfo.state === 'refunded') {
    return (
      <ContentNotQualified>
        <p>
          Unfortunately, you did not win the lucky draw. Your tUSDT has been refunded to your original account.
        </p>
      </ContentNotQualified>
    );
  }

  if (purchaseInfo.state === 'distributed') {
    const bltAmount = (parseFloat(purchaseInfo.amount) * 10)
    return (
      <ContentQualified>
        <p><b>Congratulations!</b></p>
        <p>You have successfully purchased {bltAmount} BLT in the community sale!</p>
        <p>The locked BLT has been distributed to your account.</p>
        <p><a href="https://medium.com/portto/blocto-is-excited-to-announce-the-community-sale-of-our-utility-token-blt-4233e0f52989" target="_blank" rel="noopener noreferrer">Blocto Token community sale lockup terms.</a></p>
      </ContentQualified>
    );
  }

  // initial state
  return (
    <>
      <ContentQualified>
        <h3><b>You're all set!</b></h3>
        <p>Your $BLT community sale order of <b>{parseFloat(purchaseInfo.amount)} tUSDT</b> has been successfully placed.</p>
        <p>Come back on <b>July 22th 6PM (UTC+8)</b> to check out the lucky draw result.</p>
      </ContentQualified>
      <Content>
        <p>Please read the following rules and guides while you're waiting for the result.</p>
        <ul>
          <li>
            <a href="https://medium.com/portto/blocto-is-excited-to-announce-the-community-sale-of-our-utility-token-blt-4233e0f52989" target="_blank" rel="noopener noreferrer">Blocto Token community sale rules.</a>
          </li>
          <li>
            <a href="https://medium.com/portto/blocto-is-excited-to-announce-the-community-sale-of-our-utility-token-blt-4233e0f52989" target="_blank" rel="noopener noreferrer">Blocto Token community sale lockup terms.</a>
          </li>
        </ul>
      </Content>
    </>
  );
};

export default PurchaseInfo;
