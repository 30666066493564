import React, { useState } from 'react';
import styled from "styled-components"

import Card from '../components/Card'
import bloctoFull from '../assets/logo-blocto-full.svg';
import close from '../assets/ic-close-white.svg';
import lock from '../assets/ic-lock.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;

  ul, ol {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    padding-inline-start: 20px;
  }
`;

const Lock = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;

const Notice = styled.div`
  height: ${props => props.isShown ? 36 : 0}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #D7DEE2;
  background: rgb(42, 74, 197);
  color: white;
  transition: .15s height;
  overflow: hidden;
`;

const NoticeContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const URLContent = styled.span`
  margin-left: 6px;
  background: #F8F8F840;
  border-radius: 20px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Close = styled.div`
  margin-right: 4px;
  cursor: pointer;
  border-radius: 4px;
  cursor: pointer;
  transition: .2s transform;
  transform: scale(1);
  
  &:hover {
    transform: scale(1.15);
  }
`;

const Header = styled.div`
  flex: 0 0 80px;
  height: 80px;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(127, 127, 127, 0.1);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 26px;
  font-size: 15px;
  font-weight: 600;
  box-sizing: border-box;
`;

const Body = styled.div`
  padding: 15px;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`

`;

const Seperator = styled.div`
  width: 1px;
  height: 12px;
  margin: 8px 18px;
  background-color: #c0c0c0;
`;

const Title = styled.div`
  height: 60px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #efefef;
`;

const CardBody = styled.div`
  padding: 30px;
`;

const Layout = ({ children }) => {
  const [isNoticeShowm, setNoticeShown] = useState(true);

  const closeNotice = () => {
    setNoticeShown(false)
  }

  return (
    <Wrapper>
      <Notice
        onClick={closeNotice}
        isShown={isNoticeShowm}
      >
        <NoticeContent>
          Make sure the URL is <URLContent><Lock src={lock} alt="secure" />sale.blocto.app</URLContent>
        </NoticeContent>
        <Close onClick={closeNotice}>
          <img src={close} alt="close" />
        </Close>
      </Notice>
      <Header>
        <Img src={bloctoFull} alt="logo" />
        <Seperator />
        $BLT Community Sale
      </Header>
      <Body>
        <Card>
          <Title>Blocto Token Community Sale</Title>

          <CardBody>{children}</CardBody>
        </Card>
      </Body>
    </Wrapper>
  )
}

export default Layout
