import { apiGet, apiPost } from './rest';

export const getUserInfo = ({ accessToken } = {}) =>
  apiGet({
    url: 'blocto/account/getUserInfo',
    isAuthorized: true,
    headers: {
      authorization: accessToken,
    },
  });

export const getAccountSettings = ({ accessToken } = {}) =>
  apiGet({
    url: 'blocto/account/settings',
    isAuthorized: true,
    headers: {
      authorization: accessToken,
    },
  });

export const setAccountSettings = ({ dapperEmail = null, accessToken } = {}) =>
  apiPost({
    url: 'blocto/account/settings',
    request: {
      dapper_email: dapperEmail,
    },
    headers: {
      authorization: accessToken,
    },
  });
