import { ajax } from 'rxjs/ajax';
import { map, retry } from 'rxjs/operators';
import { stringify } from 'query-string';
import { BASE_RESTFUL_URL } from './constants';

const VERSION_NUM = 11400;

export const apiGet = ({ url = '', request = {}, headers = {}, retries = 1 }) =>
  ajax({
    url: `${BASE_RESTFUL_URL}${url}?${stringify(request)}`,
    headers: {
      Accept: 'application/json',
      web_version: VERSION_NUM,
      ...headers,
    },
    crossDomain: true,
  })
    .pipe(
      retry(retries),
      map(e => e.response)
    );

export const apiPost = ({ url = '', request = {}, headers = {} }) =>
  ajax({
    url: `${BASE_RESTFUL_URL}${url}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      web_version: VERSION_NUM,
      nonce: Date.now(),
      ...headers,
    },
    body: JSON.stringify(request),
    crossDomain: true,
  })
    .pipe(
      map(e => e.response)
    );

export const apiPut = ({ url = '', request = {}, headers = {} }) =>
  ajax({
    url: `${BASE_RESTFUL_URL}${url}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      web_version: VERSION_NUM,
      nonce: Date.now(),
      ...headers,
    },
    body: JSON.stringify(request),
    crossDomain: true,
  })
    .pipe(
      map(e => e.response)
    );
