import { useState, useEffect } from 'react'
import * as fcl from '@onflow/fcl'
import * as t from "@onflow/types"
import useTimer from './useTimer'

const script = `
import BloctoTokenSale from 0x0f9df91c9121c460

pub struct PurchaseInfo {
    // Purchaser address
    pub let address: Address

    // Purchase amount in tUSDT
    pub let amount: UFix64

    // Random ticket ID
    pub let ticketId: UInt64

    // State of the purchase
    pub let state: String

    init(
        address: Address,
        amount: UFix64,
        ticketId: UInt64,
        state: String
    ) {
        self.address = address
        self.amount = amount
        self.ticketId = ticketId
        self.state = state
    }
}

pub fun main(address: Address): PurchaseInfo? {
    let purchaseInfo = BloctoTokenSale.getPurchase(address: address)

    if purchaseInfo == nil {
        return nil
    }

    var state = ""

    if purchaseInfo!.state == BloctoTokenSale.PurchaseState.initial {
        state = "initial"
    } else if purchaseInfo!.state == BloctoTokenSale.PurchaseState.distributed {
        state = "distributed"
    } else {
        state = "refunded"
    }

    let processedPurchaseInfo = PurchaseInfo(
        address: purchaseInfo!.address,
        amount: purchaseInfo!.amount,
        ticketId: purchaseInfo!.ticketId,
        state: state
    )

    return processedPurchaseInfo
}
`

export default function useGetPurchaseInfo(address, isProcessing) {
    const [purchaseInfo, setPurchaseInfo] = useState(null)

    // auto refresh every 10 seconds
    const nonce = useTimer(10000)

    useEffect(() => {
        if (!address) {
            setPurchaseInfo(null)
            return;
        }

        fcl.send([
            fcl.script(script),
            fcl.args([fcl.arg(address, t.Address)]),
        ])
            .then(fcl.decode)
            .then(setPurchaseInfo);
    }, [nonce, address, isProcessing])

    return purchaseInfo
}
